import React, { useState } from 'react'
import styles from './Notfound.module.css'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import HeroSectionimg from '../../img/Home/Component18.webp'
import { Link } from 'react-router-dom'

const Notfound = () => {
    const [banner, setbanner] = useState(HeroSectionimg)

    return (
        <div className={styles.HeroSection} style={{ backgroundImage: `url(${banner})` }}>
            <ScrollAnimation>
                <h1>Page not available</h1>
                <h4>Go to <Link to='/' style={{ color: 'white', }} >Home</Link> </h4>
            </ScrollAnimation>
        </div>
    )
}

export default Notfound

import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import HeroSectionimg from '../../img/Home/Component18.webp'
import styles from './HeroSection.module.css'
import { MdKeyboardArrowDown } from "react-icons/md";
import TopBanner from '../../components/TopBanner/TopBanner'
import { useReservation } from "../../context/ReservationContext"; // Adjust the import path
const HeroSection = () => {
    // eslint-disable-next-line
    const [banner, setbanner] = useState(HeroSectionimg)
    const today = new Date().toISOString().split('T')[0];
    const {
        checkInDate,
        setCheckInDate,
        checkOutDate,
        setCheckOutDate,
        rooms,
        setRooms,
        adult,
        setAdult,
        child,
        setChild,
    } = useReservation();
    // const [CheckInDate, setCheckInDate] = useState(today)
    const [tomorrowDate, setTomorrowDate] = useState('');

    useEffect(() => {
        const tomorrow = new Date(checkInDate);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setTomorrowDate(tomorrow.toISOString().split('T')[0]);
    }, [checkInDate]);
    // const [CheckOutDate, setCheckOutDate] = useState(tomorrowDate)
    useEffect(() => {
        setCheckOutDate(tomorrowDate)
        // eslint-disable-next-line
    }, [tomorrowDate])

    const handleCheckInDateChange = (event) => {
        const selectedDate = event.target.value;
        setCheckInDate(selectedDate);
    };
    const handleCheckOutDateChange = (event) => {
        const selectedDate = event.target.value;
        setCheckOutDate(selectedDate);
    };

    const [showrooms, setshowrooms] = useState(false)

    const [showadult, setshowadults] = useState(false)
    const increment = (type) => {
        switch (type) {
            case 'rooms':
                setRooms(rooms + 1);
                break;
            case 'adults':
                setAdult(adult + 1);
                break;
            case 'children':
                setChild(child + 1);
                break;
            default:
                break;
        }
    };

    const decrement = (type) => {
        switch (type) {
            case 'rooms':
                if (rooms > 1) {
                    setRooms(rooms - 1);
                }
                break;
            case 'adults':
                if (adult > 1) {
                    setAdult(adult - 1);
                }
                break;
            case 'children':
                if (child > 0) {
                    setChild(child - 1);
                }
                break;
            default:
                break;
        }
    };
    return (
        <>
            <TopBanner />
            <div className={styles.HeroSection} >
                {/* <ScrollTopDiv> */}
                <div className={styles.floatingdiv}>
                    <div className={styles.griditem}>
                        <p>Check In</p>
                        <input type="date" id="Test_DatetimeLocal" value={checkInDate} onChange={handleCheckInDateChange} min={today} />
                    </div>
                    <div className={styles.griditem}>
                        <p>Check Out</p>
                        <input type="date" id="Test_DatetimeLocal" value={checkOutDate} onChange={handleCheckOutDateChange} min={tomorrowDate} />
                    </div>
                    <div className={styles.griditem} >
                        <p>Rooms </p>
                        <div className={styles.inneritem} onClick={() => setshowrooms(!showrooms)}>
                            <p>{rooms}</p>
                            <MdKeyboardArrowDown />
                        </div>
                        {showrooms &&
                            <div className={styles.spinner}>
                                <p>Rooms</p>
                                <div className={styles.custominputspinner}>
                                    <button className={styles.spinnerbutton} onClick={() => decrement('rooms')}>
                                        -
                                    </button>
                                    <input type="text" className={styles.spinnerinput} value={rooms} readOnly />
                                    <button className={styles.spinnerbutton} onClick={() => increment('rooms')}>
                                        +
                                    </button>
                                </div>
                            </div>}
                    </div>
                    <div className={styles.griditem}>
                        <p>Guests </p>
                        <div className={styles.inneritem} onClick={() => setshowadults(!showadult)}>
                            <p>{adult} Adult ,{child} Child </p>
                            <MdKeyboardArrowDown />
                        </div>
                        {showadult &&
                            <div className={styles.spinner2}>
                                <div className={styles.spinner2column}>
                                    <p>Adults</p>
                                    <div className={styles.custominputspinner}>
                                        <button className={styles.spinnerbutton} onClick={() => decrement('adults')}>
                                            -
                                        </button>
                                        <input type="text" className={styles.spinnerinput} value={adult} readOnly />
                                        <button className={styles.spinnerbutton} onClick={() => increment('adults')}>
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.spinner2column}>
                                    <p>Child</p>
                                    <div className={styles.custominputspinner}>
                                        <button className={styles.spinnerbutton} onClick={() => decrement('children')}>
                                            -
                                        </button>
                                        <input type="text" className={styles.spinnerinput} value={child} readOnly />
                                        <button className={styles.spinnerbutton} onClick={() => increment('children')}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.griditem}>
                        <p><Link to='/BookNow'>Check Availability</Link></p>
                    </div>
                </div>
                <div className={styles.FormDiv}>
                    <div className={styles.griditem}>
                        <p>Check In</p>
                        <input type="date" id="Test_DatetimeLocal" value={checkInDate} onChange={handleCheckInDateChange} min={today} />
                    </div>
                    <div className={styles.griditem}>
                        <p>Check Out</p>
                        <input type="date" id="Test_DatetimeLocal" value={checkOutDate} onChange={handleCheckOutDateChange} min={tomorrowDate} />
                    </div>
                    <div className={styles.griditem} >
                        <p>Rooms </p>
                        <div className={styles.inneritem} onClick={() => setshowrooms(!showrooms)}>
                            <p>{rooms}</p>
                            <MdKeyboardArrowDown />
                        </div>
                        {showrooms &&
                            <div className={styles.spinner}>
                                <p>Rooms</p>
                                <div className={styles.custominputspinner}>
                                    <button className={styles.spinnerbutton} onClick={() => decrement('rooms')}>
                                        -
                                    </button>
                                    <input type="text" className={styles.spinnerinput} value={rooms} readOnly />
                                    <button className={styles.spinnerbutton} onClick={() => increment('rooms')}>
                                        +
                                    </button>
                                </div>
                            </div>}
                    </div>
                    <div className={styles.griditem}>
                        <p>Guests </p>
                        <div className={styles.inneritem} onClick={() => setshowadults(!showadult)}>
                            <p>{adult} Adult ,{child} Child </p>
                            <MdKeyboardArrowDown />
                        </div>
                        {showadult &&
                            <div className={styles.spinner2}>
                                <div className={styles.spinner2column}>
                                    <p>Adults</p>
                                    <div className={styles.custominputspinner}>
                                        <button className={styles.spinnerbutton} onClick={() => decrement('adults')}>
                                            -
                                        </button>
                                        <input type="text" className={styles.spinnerinput} value={adult} readOnly />
                                        <button className={styles.spinnerbutton} onClick={() => increment('adults')}>
                                            +
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.spinner2column}>
                                    <p>Child</p>
                                    <div className={styles.custominputspinner}>
                                        <button className={styles.spinnerbutton} onClick={() => decrement('children')}>
                                            -
                                        </button>
                                        <input type="text" className={styles.spinnerinput} value={child} readOnly />
                                        <button className={styles.spinnerbutton} onClick={() => increment('children')}>
                                            +
                                        </button>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className={styles.griditem}>
                        <p><Link to='/BookNow'>Check Availability</Link></p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroSection

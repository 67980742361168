import React, { useState, useEffect } from 'react'
import styles from './Testimonial.module.css'
import { FaStar } from "react-icons/fa";

const slideStyles = {
    width: "100%",
    height: "100%",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: 'opacity 0.5s ease',
};
const sliderStyles = {
    position: "relative",
    height: "100%",
    overflow: 'hidden',
};
// eslint-disable-next-line
const dotsContainerStyles = {
    display: "flex",
    justifyContent: "center",
    marginTop: "-80px"
};
// eslint-disable-next-line
const dotStyle = {
    margin: "0 3px",
    cursor: "pointer",
    fontSize: "20px",
    color: "#ccc", // Default color for inactive dots
};

const activeDotStyle = {
    ...dotStyle,
    color: "rgb(70, 70, 70)", // Color for active dot
};
const ImageSlider = ({ slides }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const goToPrevious = () => {
        const isFirstSlide = currentIndex === 0;
        const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
        setCurrentIndex(newIndex);
    };
    const goToNext = () => {
        const isLastSlide = currentIndex === slides.length - 1;
        const newIndex = isLastSlide ? 0 : currentIndex + 1;
        setCurrentIndex(newIndex);

    };

    const slideStylesWidthBackground = {
        ...slideStyles,
        backgroundImage: `url(${slides[currentIndex].url})`,
    };
    useEffect(() => {
        // Set up an interval to change the slide every 5 seconds
        const intervalId = setInterval(goToNext, 3000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [currentIndex, slides]);

    return (
        <div style={sliderStyles}>
            <div>
                <div onClick={goToPrevious} className={styles.leftArrowStyles}>
                    ❰
                </div>
                <div onClick={goToNext} className={styles.rightArrowStyles}>
                    ❱
                </div>
            </div>
            <div className={styles.slides} style={slideStylesWidthBackground}>
                <h3><FaStar /><FaStar /><FaStar /><FaStar /><FaStar /></h3>
                <p>{slides[currentIndex].review}</p>
                <h5>{slides[currentIndex].customer}</h5>
            </div>
            <div style={dotsContainerStyles}>
                {slides.map((slide, slideIndex) => (
                    <div
                        style={slideIndex === currentIndex ? activeDotStyle : dotStyle}
                        key={slideIndex}
                        onClick={() => setCurrentIndex(slideIndex)}
                    >
                        ●
                    </div>
                ))}
            </div>
        </div>
    );
};


const Testimonial = () => {
    const slides = [
        { customer: "Sophie Launer", title: "beach", review: "Best part being the places where it lies, the place was quiet and best for weekend gateway. Fireplace in each room was a good addition. Something that could be better was the behaviour of staffs, placement of toothpaste and shampoos in rooms, lowering the speed of shower." },
        { customer: "Pawan Thapa", title: "beach", review: "“ Very nice place to relax yourself close to nature. Friendly staff, friendly cat and good food with comfortable and spacious rooms.”" },
        { customer: "Prakash Neupane", title: "beach", review: "This is a beautiful place in Nagarkot.If you want good food, good ambience, good view, goid service in a budget, then this is the right place. :) 💯" },
        { customer: "APA Bikes & Hikes", title: "beach", review: "Nagarkot Farmhouse, a lovely simple lodge isolated from the rest of the hotels on the northern  side of nagarkot is best for mountain views." },
        { customer: "Outside Travel Guide", title: "beach", review: "A Farmhouse in Nepal . Sometimes the best hideways are those the ones you're least to hear about" },
        { customer: "Kerry Moran, Moon Publications", title: "beach", review: "Nagarkot Farmhouse, a renovated old newar house with raised ceiling, fireplace , a small library and a monopoly game is something special" },
    ];

    return (
        <div className={styles.TestimonialsSection}>
            <div className={styles.containerStyles} >
                <ImageSlider slides={slides} />
            </div>
        </div>
    )

}

export default Testimonial

import React, { createContext, useState, useContext } from 'react';

const ReservationContext = createContext();

export const ReservationProvider = ({ children }) => {
    const [checkInDate, setCheckInDate] = useState(new Date().toISOString().split('T')[0]);
    const [checkOutDate, setCheckOutDate] = useState('');
    const [rooms, setRooms] = useState(1);
    const [adult, setAdult] = useState(1);
    const [child, setChild] = useState(0);
    const [roomType, setRoomType] = useState('Standard Room');
    const [homeModalOpen, sethomeModalOpen] = useState(true);
    return (
        <ReservationContext.Provider
            value={{
                checkInDate,
                setCheckInDate,
                checkOutDate,
                setCheckOutDate,
                rooms,
                setRooms,
                adult,
                setAdult,
                child,
                setChild,
                homeModalOpen,
                sethomeModalOpen,
                setRoomType,
                roomType
            }}
        >
            {children}
        </ReservationContext.Provider>
    );
};

export const useReservation = () => {
    const context = useContext(ReservationContext);
    if (!context) {
        throw new Error('useReservation must be used within a ReservationProvider');
    }
    return context;
};
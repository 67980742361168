import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import HeroSectionimg from '../../img/Home/Component18.webp'
import styles from './TopBanner.module.css'
import ScrollAnimation from '../Animation/ScrollAnimation'

const TopBanner = () => {
    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname.substring(1));
    const [message, setmessage] = useState('Your Secluded Hilltop')
    // The forest paradise of warmth, tranquility and restoration
    useEffect(() => {
        if (pathname === 'BookNow') {
            setPathname('Book Online')
        }
        else if (pathname === '') {
            setPathname('Nagarkot Farm House')
            setmessage('Your Secluded Hilltop')
        }
        else if (pathname === 'about') {
            setPathname('About')
        }
        else if (pathname === 'reviews') {
            setPathname('Reviews')
        }
        else if (pathname === 'gallery') {
            setPathname('Gallery')
        }
        else if (pathname === 'contact') {
            setPathname('Contact Us')
        }
        else if (pathname === 'restaurant') {
            setPathname('Restaurant')
            setmessage('Carefully curated menus incorporate locally sourced ingredients into exquisite dishes that reflect the locals and the world around them.')
        }
        else if (pathname === 'rooms') {
            setPathname('Rooms')
        }
        else if (pathname === 'localActivities') {
            setPathname('Local Activities')
        }
    }, [pathname])
    // eslint-disable-next-line
    const [banner, setbanner] = useState(HeroSectionimg)
    return (
        <div className={styles.HeroSection} style={{ backgroundImage: `url(${banner})` }}>
            <ScrollAnimation>
                <h1>{pathname}</h1>
                <h4>{message}</h4>
            </ScrollAnimation>
        </div>
    )
}

export default TopBanner

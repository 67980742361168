import React, { useRef } from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './Book.module.css'
import { useReservation } from "../../context/ReservationContext"; // Adjust the import path
import { motion } from "framer-motion"
import emailjs from "@emailjs/browser";

const Book = () => {
    const today = new Date().toISOString().split('T')[0];
    const {
        checkInDate,
        setCheckInDate,
        checkOutDate,
        setCheckOutDate,
        rooms,
        setRooms,
        adult,
        setAdult,
        child,
        setChild,
        setRoomType,
        roomType

    } = useReservation();
    const handleCheckInDateChange = (event) => {
        const selectedDate = event.target.value;
        setCheckInDate(selectedDate);
    };
    const handleCheckOutDateChange = (event) => {
        const selectedDate = event.target.value;
        setCheckOutDate(selectedDate);
    };
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                "service_2pqqryf",
                "template_jh9chv9",
                form.current,
                "3GfrQ1-Z44Bu51Gqt"
            )
            .then(
                (result) => {
                    // console.log(result.text);
                    alert("Message has been sent!");
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    const handleSubmitEmail = (e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        sendEmail(e);
        form.current.reset();
    };

    return (
        <div>
            <TopBanner />
            <div className={styles.SecondSection}>
                <div className={styles.Leftsection}>
                    <motion.div initial={{ opacity: 0, x: -75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            bounce: 0.4,
                        }}>

                        <h4>Contact Information</h4>
                        <h1>Nagarkot Farm House</h1>
                        <p>Madan Dev Pura, Nagarkot</p>
                        <p><b>Phone no: </b><a href="tel:+9779749499983">+9779749499983</a>,<a href="tel:+9779802070675">+9779802070675</a></p>
                        <p><b>Email: </b><a href="mailto:info@nagarkotfarmhouse.com">info@nagarkotfarmhouse.com</a></p>
                        <div className={styles.hr}></div>

                        <h4>City Office</h4>
                        <h1>Hotel Vajra</h1>
                        <p>Bijeswari, Swayambhu</p>
                        <p>Kathmandu, Nepal</p>
                        <p><b>Phone no: </b><a href="tel:+977-1-5371545">+977-1-5371545</a>,<a href="tel:+977-1-5371545">+977-1-5371695</a></p>
                        <p><b>Email: </b><a href="mailto:info@hotelvajra.com">info@hotelvajra.com</a></p>
                        <div className={styles.hr}></div>
                    </motion.div>
                </div>
                <div className={styles.Rightsection}>
                    <motion.div className={styles.BookingSection}
                        initial={{ opacity: 0, x: 75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            bounce: 0.4,
                        }}>

                        <div className={styles.container}>
                            <h1>Book Your Room Now</h1>
                            <form className={styles.formGrid} ref={form} onSubmit={(e) => handleSubmitEmail(e)}>
                                <div className={styles.formItem}>
                                    <label htmlFor="name">Name</label>
                                    <input type='text' placeholder='FullName' name="name" required />
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="Phone no.">Phone no.</label>
                                    <input type='text' placeholder='Phone' name="phone" required />
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="email">Email</label>
                                    <input type='email' placeholder='Email' name="email" required />
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="Rooms">Rooms</label>
                                    <select id="Rooms" name="rooms" value={rooms} onChange={(e) => setRooms(e.target.value)} required >
                                        <option value="1" selected>1</option>
                                        <option value="2">2</option>
                                        <option value="3" >3</option>
                                    </select>
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="Adult">Adult</label>
                                    <select id="Adult" name="Adult" value={adult} onChange={(e) => setAdult(e.target.value)} required >
                                        <option value="1" selected>1</option>
                                        <option value="2">2</option>
                                        <option value="3" >3</option>
                                    </select>
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="Child">Child</label>
                                    <select id="Child" name="Child" value={child} onChange={(e) => setChild(e.target.value)} required>
                                        <option value="0" selected>0</option>
                                        <option value="1" >1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                    </select>
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="checkInDate">Arrival</label>
                                    <input type="date" name="CheckInDate" id="Test_DatetimeLocal" value={checkInDate} onChange={handleCheckInDateChange} min={today} />
                                </div>
                                <div className={styles.formItem}>
                                    <label htmlFor="checkInDate">Departure</label>
                                    <input type="date" name="CheckOutDate" id="Test_DatetimeLocal" value={checkOutDate} onChange={handleCheckOutDateChange} min={today} />
                                </div>
                                <div className={`${styles.formItem} ${styles.span2}`}>
                                    <label htmlFor="roomType">Room Type:</label>
                                    <select id="roomType" name="roomType" value={roomType} onChange={(e) => setRoomType(e.target.value)} required>
                                        <option value="Standard Room" selected>Standard Room</option>
                                        <option value="Deluxe Room">Deluxe Room</option>
                                    </select>
                                </div>
                                <div className={`${styles.formItem} ${styles.span2}`}>
                                    <label htmlFor="Comments">Comments</label>
                                    <textarea rows="5" name="Comments" type="text" placeholder='Provide any details or specific information you would like to share' />
                                </div>
                                <div className={`${styles.formItem} ${styles.span2} ${styles.button}`}>
                                    <button className={styles.btn} id={styles.btn1} type="submit">Submit</button>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div >
    )
}

export default Book

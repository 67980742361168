import React, { useEffect, useState } from 'react'
import styles from './NotJustSection.module.css'
import YouTube from 'react-youtube'

const NotJustSection = () => {
    const videoUrl = 'https://youtu.be/TPWxlQJpvpQ';
    // const videoUrl = process.env.PUBLIC_URL + '/public/Video1.mp4'
    const videoId = extractVideoId(videoUrl);

    function extractVideoId(url) {
        // Extract the video ID from the YouTube URL
        // eslint-disable-next-line
        const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
        return match && match[1];
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={styles.TopSection}>
            <div className={styles.LeftSection}>
                <YouTube
                    videoId={videoId}
                    opts={{
                        playerVars: {
                            autoplay: 1,
                            controls: 1,
                            mute: 1,
                            loop: 1,
                        },
                    }}
                // onReady={handleVideoReady}
                // onEnd={handleVideoEnd}
                />
            </div>

            <div className={styles.RightSection}>
                <h4>ENDLESS INDOOR AND OUTDOOR VIEWS</h4>
                <h1>Nagarkot Farm House</h1>
                <p>The Nagarkot Farm House Resort is one of the best hotel in Nepal since 28 years. It is wonderful place to stay with nice view.Uphill farmhouse with cute gardens and lovely coloured flowers. Simple rooms with fireplace inside. The food is simple and tasty. It’s best place to stay for writers, painters, poets, professionals. It will provide the solace and inspiration for beautiful creations.</p>
            </div>
        </div>
    )
}

export default NotJustSection

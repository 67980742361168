import React, { useEffect } from 'react';
import TopBanner from '../../components/TopBanner/TopBanner';

const TrustMaryWidget = () => {
    const url = "https://widget.trustmary.com/d0B7a6GuA";

    // useEffect(() => {
    //     const container = document.getElementById("trustMaryContainer");

    //     if (!container) {
    //         console.error("Container element not found");
    //         return;
    //     }

    //     const script = document.createElement("script");
    //     script.setAttribute("src", url);
    //     container.appendChild(script);

    //     // Clean up resources provided by the script (if any)
    //     return () => {
    //         // if (script.cleanup) {
    //         //     script.cleanup();
    //         // }

    //         if (container.contains(script)) {
    //             container.removeChild(script);
    //         }
    //     };
    // }, [url]);
    useEffect(() => {
        try {
            const container = document.getElementById("trustMaryContainer");

            if (!container) {
                console.error("Container element not found");
                return;
            }

            const script = document.createElement("script");
            script.setAttribute("src", url);
            container.appendChild(script);
        } catch (error) {
            // Handle the error gracefully
            console.error("An error occurred:", error);
        }
    }, [url]);
    return null;
};

const Reviews = () => {
    return (
        <div>
            <TopBanner />
            {/* Container for TrustMaryWidget */}
            <div id="trustMaryContainer"></div>
            <TrustMaryWidget />
        </div>
    );
};

export default Reviews;


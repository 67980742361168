import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from "framer-motion"

const ScrollAnimation = ({ children }) => {
    const ref = useRef();
    const isInView = useInView(ref, { once: true });

    const mainControls = useAnimation();
    useEffect(() => {
        if (isInView) {
            mainControls.start("visible")
        }
        // eslint-disable-next-line 
    }, [isInView]);
    return (
        <motion.div ref={ref}
            variants={{
                hidden: {
                    rotateX: 80,
                    translateY: 50,
                    opacity: 0
                },
                visible: {
                    rotateX: 0, translateY: 0, opacity: 1
                }
            }}
            initial="hidden"
            animate={mainControls}
            transition={{ duration: 1, ease: [0.42, 0, 0.58, 1] }}
        >
            {children}</motion.div>

    )
}

export default ScrollAnimation


import React from 'react'
import styles from './Footer.module.css'
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaTripadvisor } from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";

const Footer = () => {
    return (
        <div className={styles.TopFooter}>
            <div className={styles.topSection}>
                <div className={styles.gridcontainer}>
                    <div className={styles.items}>
                        <div className={styles.itemheading}>
                            ADDRESS
                        </div>
                        <p> Madan Dev Pura, Nagarkot</p>
                    </div>
                    <div className={styles.items}>
                        <div className={styles.itemheading}>
                            PHONE
                        </div>
                        <div className={styles.numberLink}>
                            <a href="tel:+9779802070674">
                                <p>+9779802070674, </p>
                            </a>
                            <a href="tel:+9779802070675">
                                <p> 9802070675</p>
                            </a>
                        </div>
                    </div>
                    <div className={styles.items}>
                        <div className={styles.itemheading}>
                            EMAIL
                        </div>
                        <a href="mailto:info@nagarkotfarmhouse.com">
                            <p>info@nagarkotfarmhouse.com </p>
                        </a>

                    </div>
                    <div className={styles.items}>
                        <div className={styles.itemheading}>
                            SOCIALS
                        </div>
                        <div className={styles.socialLinks}>
                            <div className={styles.tooltip}>
                                <a href="https://www.facebook.com/nagarkotfarmhouseresort" rel="noreferrer" target="_blank" data-tip="Facebook">
                                    <FaFacebookF />
                                </a>
                                <span className={styles.tooltiptext}>Facebook</span>
                            </div>
                            <div className={styles.tooltip}>
                                <a href="https://www.instagram.com/nagarkot.farmhouse/" rel="noreferrer" target="_blank" data-tip="Instagram">
                                    <BsInstagram />
                                </a>
                                <span className={styles.tooltiptext}>Instagram</span>
                            </div>
                            <div className={styles.tooltip}>
                                <a href="https://www.tripadvisor.com" rel="noreferrer" target="_blank" data-tip="Tripadvisor">
                                    <FaTripadvisor />
                                </a>
                                <span className={styles.tooltiptext}>Tripadvisor </span>
                            </div>
                            <div className={styles.tooltip}>
                                <a href="https://www.booking.com/hotel/np/nagarkot-farmhouse-resort-pvt-ltd.en-gb.html?lang=en-gb&soz=1&lang_changed=1" rel="noreferrer" target="_blank" data-tip="Tripadvisor">
                                    <TbBrandBooking />
                                </a>
                                <span className={styles.tooltiptext}>Booking </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.griditemfull}>
                    © Copyright Nagarkot Farm House
                </div>
            </div>
        </div>
    )
}

export default Footer

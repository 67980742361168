import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ScrollToTop from './pages/ScrollToTop/ScrollToTop'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home/Home'
import About from './pages/About/About'
import Contact from './pages/Contact/Contact'
import { ReservationProvider } from './context/ReservationContext'
import Book from './pages/BookNow/Book'
import Reviews from './pages/Reviews/Reviews'
import Restaurant from './pages/Restaurant/Restaurant'
import LocalActivities from './pages/LocalActivities/LocalActivities'
import Rooms from './pages/Rooms/Rooms'
import Notfound from './pages/Notfound/Notfound'
function App() {

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <ReservationProvider>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/rooms" element={<Rooms />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/localActivities" exact element={<LocalActivities />} />
          <Route path="/BookNow" exact element={<Book />} />
          <Route path="/about" exact element={<About />} />
          <Route path="/reviews" exact element={<Reviews />} />
          <Route path="/restaurant" exact element={<Restaurant />} />
          <Route path="*" element={<Notfound />} />
        </Routes>
      </ReservationProvider>
      <Footer />
    </BrowserRouter>
  );
}

export default App;

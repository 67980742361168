import React, { useState, useEffect, useRef } from 'react'
import { useLocation, Link, NavLink } from 'react-router-dom'
import NavbarAnimation from './Animation/NavbarAnimation';
import styles from './Navbar.module.css'
import * as FaIcons from "react-icons/fa6";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from 'react-icons/bs';

import { IconContext } from 'react-icons';
import Logo from '../img/Home/Component19.webp';
// import menu from '../img/Flaticon/menu1.webp';
import { BsArrowDownCircle, BsArrowUpCircle } from 'react-icons/bs'
import { useInView } from "framer-motion"
// import { createPortal } from 'react-dom'


const Navbar = () => {
    const [sidebar, setSidebar] = useState(false)
    const showSidebar = () => setSidebar(!sidebar)
    const location = useLocation();

    let locationString1 = location.pathname.slice(1);
    const shouldShowNavbar = locationString1.includes('StrategySession');

    const ref = useRef();
    const isInView = useInView(ref);

    const [uparrow, setuparrow] = useState(false);

    useEffect(() => {
        if (isInView) {
            setuparrow(true)
        }
    }, [isInView, uparrow])
    useEffect(() => {
        let locationString = location.pathname.slice(1);
        if (!locationString) {
            locationString = "home"
        }
        const str2 = locationString.charAt(0).toUpperCase() + locationString.slice(1);
        // const str2 = locationString.toUpperCase();
        let titleString = "Nagarkot Farm House - " + str2;
        document.title = titleString
    }, [location.pathname]);

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [dropdownHelp, setDropdownHelp] = useState(false);
    const [dropdownwho, setDropdownwho] = useState(false);
    // eslint-disable-next-line 
    const handleDropdownCclick = (e) => {
        setDropdownVisible(!dropdownVisible);
        if (dropdownVisible) {
            e.target.classList.add(styles.color);
        } else {
            e.target.classList.remove(styles.color);
        }
    };
    // eslint-disable-next-line 
    const handleClickHelp = (e) => {
        setDropdownHelp(!dropdownHelp);
        if (dropdownHelp) {
            e.target.classList.add(styles.color);
        } else {
            e.target.classList.remove(styles.color);
        }
    }
    // eslint-disable-next-line 
    const handleClickWho = (e) => {
        setDropdownwho(!dropdownwho);
        if (dropdownwho) {
            e.target.classList.add(styles.color);
        } else {
            e.target.classList.remove(styles.color);
        }
    }

    const changecolor = () => {
        const scrollbarDiv = document.querySelector('#ScrollBar');
        if (scrollbarDiv) {
            if (window.scrollY > 200 && scrollbarDiv) {
                scrollbarDiv.classList.add(styles.ShowOnScroll);
            } else {
                scrollbarDiv.classList.remove(styles.ShowOnScroll);
            }
        }

    };

    useEffect(() => {
        window.addEventListener('scroll', changecolor);
        return () => {
            window.removeEventListener('scroll', changecolor);
        };
    }, []);

    return (
        <>
            {!shouldShowNavbar &&
                <div className={styles.topHeader}>
                    <header>
                        <nav className={`${styles.topmenu} ${styles.fixed}`} >
                            <div className={styles.navbar}>
                                <a href="tel:+9779749499983" style={{ marginLeft: "5px" }} className={`${styles.menubars} ${styles.navbaranchor}`}>
                                    <BsIcons.BsTelephoneFill />
                                </a>
                                <div className={styles.logo}>
                                    <NavLink to="/" >
                                        <img src={Logo} alt="logo" />
                                        {/* NAGARKOT FARM HOUSE */}
                                    </NavLink>
                                </div>
                                <div className={styles.text}>
                                    <Link to="/" className={location.pathname === '/' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>HOME</Link>
                                    <Link to="/about" className={location.pathname.slice(1) === 'about' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>ABOUT</Link>
                                    <div className={styles.dropdownContainer}>
                                        <Link className={location.pathname.slice(1) === 'rooms' || location.pathname.slice(1) === 'localActivities' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>FACILITIES</Link>
                                        <div className={styles.dropdownContent}>
                                            <Link to="/rooms" className={styles.dropdownItem}>ROOMS</Link>
                                            <Link to="/localActivities" className={styles.dropdownItem}>LOCAL ACTIVITIES</Link>
                                            {/* <Link to="/restaurant" className={styles.dropdownItem}>RESTAURANT</Link> */}
                                        </div>
                                    </div>
                                    {/* <Link to="/affiliates" className={location.pathname.slice(1) === 'affiliates' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>ROOMS</Link> */}
                                    {/* <Link to="/gallery" className={location.pathname.slice(1) === 'hosting' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>GALLERY</Link> */}
                                    <Link to="/reviews" className={location.pathname.slice(1) === 'reviews' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>REVIEWS</Link>
                                    <Link to="/contact" className={location.pathname.slice(1) === 'contact' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>CONTACT</Link>
                                    <Link to="/BookNow" className={`${styles.navitem} ${styles.btn} `}>
                                        BOOK NOW
                                    </Link>
                                </div>

                                <Link to="#" className={styles.menubars}>
                                    <FaIcons.FaBars onClick={showSidebar} />
                                    {/* <img src={menu} alt='menu' onClick={showSidebar} /> */}
                                </Link>
                            </div>
                        </nav>

                        <nav className={styles.ScrollBar} id='ScrollBar' >
                            <div className={styles.navbarScroll}>
                                <a href="tel:+9779749499983" className={styles.menubars}>
                                    <BsIcons.BsTelephoneFill />
                                </a>
                                <div className={styles.logo}>
                                    <NavLink to="/" >
                                        {/* NAGARKOT FARM HOUSE */}
                                        <img src={Logo} alt="logo" />
                                    </NavLink>
                                </div>
                                <div className={styles.text}>
                                    <Link to="/" className={location.pathname === '/' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>HOME</Link>
                                    <Link to="/about" className={location.pathname.slice(1) === 'about' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>ABOUT</Link>
                                    <div className={styles.dropdownContainer}>
                                        <Link className={location.pathname.slice(1) === 'rooms' || location.pathname.slice(1) === 'localActivities' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>FACILITIES</Link>
                                        <div className={styles.dropdownContent}>
                                            <Link to="/rooms" className={styles.dropdownItem}>ROOMS</Link>
                                            <Link to="/localActivities" className={styles.dropdownItem}>LOCAL ACTIVITIES</Link>
                                            {/* <Link to="/restaurant" className={styles.dropdownItem}>RESTAURANT</Link> */}
                                        </div>
                                    </div>
                                    {/* <Link to="/affiliates" className={location.pathname.slice(1) === 'affiliates' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>ROOMS</Link> */}
                                    {/* <Link to="/gallery" className={location.pathname.slice(1) === 'hosting' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>GALLERY</Link> */}
                                    <Link to="/reviews" className={location.pathname.slice(1) === 'reviews' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>REVIEWS</Link>
                                    <Link to="/contact" className={location.pathname.slice(1) === 'contact' ? `${styles.navitem} ${styles.navitemactive}` : styles.navitem}>CONTACT</Link>
                                    <Link to="/BookNow" className={`${styles.navitem} ${styles.btn} `}>
                                        BOOK NOW
                                    </Link>
                                </div>
                                <Link to="#" className={styles.menubars}>
                                    <FaIcons.FaBars onClick={showSidebar} />
                                    {/* <img src={menu} alt='menu' onClick={showSidebar} /> */}
                                </Link>
                            </div>
                        </nav>

                        <nav className={`${sidebar ? `${styles.navmenu} ${styles.active}` : styles.navmenu}`}>
                            <ul className={styles.navMenuItems} style={{ scrollBehavior: 'smooth' }}>
                                {!uparrow && sidebar && <div className={styles.downArrow}>
                                    <a href="#Follow" alt="linkdown" onClick={() => setuparrow(true)}>
                                        <BsArrowDownCircle />
                                    </a>
                                </div>}
                                {uparrow && sidebar && <div className={styles.upArrow}>
                                    <a href="#Top" alt="linkdown" onClick={() => setuparrow(false)}>
                                        <BsArrowUpCircle />
                                    </a>
                                </div>}
                                <li className={styles.navbarToggle} id="Top">
                                    <IconContext.Provider value={{ color: 'white' }}>
                                        <Link to='#' className={styles.menubars} onClick={showSidebar}>
                                            <AiIcons.AiOutlineClose />
                                        </Link>
                                    </IconContext.Provider>
                                </li>
                                <li style={{ textAlign: 'center', marginBottom: '1.5em' }}>
                                    <img className={styles.logo} src={Logo} alt="logo" />
                                    {/* NAGARKOT FARM HOUSE */}
                                </li>
                                <div className={styles.container}>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='/' onClick={showSidebar} >
                                                <div className={styles.ScrollNavItem1}>
                                                    <h3>HOME</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='/about' onClick={showSidebar}>
                                                <div className={styles.ScrollNavItem2}>
                                                    <h3>ABOUT</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='/rooms' onClick={showSidebar}>
                                                <div className={styles.ScrollNavItem3}>
                                                    <h3>ROOMS</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='/localActivities' onClick={showSidebar}>
                                                <div className={styles.ScrollNavItem4}>
                                                    <h3>ACTIVITIES</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='/BookNow' onClick={showSidebar}>
                                                <div className={styles.ScrollNavItem7}>
                                                    <h3 >BOOKNOW</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>

                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='/contact' onClick={showSidebar}>
                                                <div className={styles.ScrollNavItem6}>
                                                    <h3 >CONTACT</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                    <div className={styles.item}>
                                        <NavbarAnimation>
                                            <Link to='reviews' onClick={showSidebar}>
                                                <div className={styles.ScrollNavItem5}>
                                                    <h3 >REVIEWS</h3>
                                                </div>
                                            </Link>
                                        </NavbarAnimation>
                                    </div>
                                </div>
                                {/* <div className={`${styles.ReadyToChat} ${styles.follow}`}>
                                    <h2>
                                        Follow Us !!
                                    </h2>
                                    <div className={styles.NavSocial}>
                                        <a href="https://www.instagram.com/murphystechnology/" id="Follow" ref={ref} target="_blank" rel="noreferrer"><AiIcons.AiOutlineInstagram /></a>
                                        <a href="https://www.facebook.com/murphysworld80" target="_blank" rel="noreferrer" ><BiIcons.BiLogoFacebook /></a>
                                    </div>
                                </div> */}


                            </ul>
                        </nav>
                    </header>
                </div>
            }
        </>
    )
}

export default Navbar


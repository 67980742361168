import React from 'react'
import styles from './About.module.css'
import img1 from '../../img/Home/img1.webp'
import img2 from '../../img/Home/img2.webp'
import img3 from '../../img/Home/img3.png'
// import room1 from '../../img/others/room1.webp'
// import room2 from '../../img/others/room2.webp'
import room1 from '../../img/others/deluxe1.webp'
import room2 from '../../img/others/deluxe2.webp'

// import area from '../../img/Flaticon/wide.webp'
import user from '../../img/Flaticon/user.webp'
import bed from '../../img/Flaticon/bed.webp'
import ScrollTopDiv from '../Animation/ScrollTopDiv'
import ScrollAnimation from '../Animation/ScrollAnimation'
import { motion } from "framer-motion"
import { useReservation } from "../../context/ReservationContext"; // Adjust the import path
import { Link } from 'react-router-dom'



const About = () => {
    const {
        setRoomType
    } = useReservation();
    const handleBookNowClick = (flag) => {
        if (flag === 0) {
            setRoomType('Standard Room');
        }
        else {
            setRoomType('Deluxe Room');
        }
    };
    return (
        <div className={styles.aboutsectionouter}>

            <div className={styles.aboutsection}>
                <ScrollTopDiv>
                    <div className={styles.gridcontainer}>
                        <div className={styles.griditem}>
                            <img src={img1} alt="img1" />
                        </div>
                        <div className={styles.griditem}>
                            <div className={styles.overlap}></div>
                            <div className={styles.overlapLine}></div>
                            <img src={img2} alt="img2" />

                            <p><ScrollAnimation>WELCOME TO NAGARKOT FARM HOUSE</ScrollAnimation></p>
                            <h1><ScrollAnimation>In the Heart of Nagarkot, Outstanding Views</ScrollAnimation></h1>
                            <p><a href="/">Explore Here</a></p>

                        </div>
                        <div className={styles.griditem}>
                            <img src={img3} alt="img1" />
                        </div>
                    </div>
                </ScrollTopDiv>


                <div className={styles.selectRooms}>
                    <motion.div className={styles.LeftSection}
                        initial={{ opacity: 0, x: -75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            bounce: 0.4,
                        }}>
                        <h4>ENJOY WORLD-CLASS STAY EXPERIENCE</h4>
                        <h1>Select Your Rooms</h1>
                        {/* <p>Escape to the beautiful mountains and valleys where dreams come true. </p> */}
                        {/* <p>Culture, nature, streams and gastronomy. Immerse yourself in the restorative </p> */}
                        {/* <p>qualities of nature, far from the disturbances of everyday life.</p> */}
                        <p>The Farmhouse has four rooms with common bath, seven deluxe rooms and four rooms with attached bath.</p>
                        <p>Cosy interiors are designed to blend with its unique cultural and natural</p>
                        <p>surroundings. Each room has a view of the Himalayas</p>
                    </motion.div>
                    <ScrollTopDiv>
                        <div className={styles.RoomOptions}>
                            <div className={styles.Item}>
                                <div className={styles.Img}>
                                    <img src={room1} alt="room1"></img>
                                    <div className={styles.PriceDiv}>
                                        <p>Standard</p>
                                    </div>
                                </div>
                                <h1>Standard Room</h1>
                                <div className={styles.info}>
                                    {/* <div>
                                        <img src={area} alt="area" />
                                        <span>80 sq ft</span>
                                    </div> */}
                                    <div>
                                        <img src={user} alt="area" />
                                        <span>2 Guests</span>
                                    </div>
                                    <div>
                                        <img src={bed} alt="area" />
                                        <span>2 Beds</span>
                                    </div>
                                </div>
                                <p>Our remarkable standard rooms offer all the amenities needed for a comfortable and cozy stay, accompanied by breathtaking views of the surrounding mountain ranges. Special requests for accommodating more than two people can be accommodated.</p>
                                <p className={styles.explorepara}><Link to='/BookNow' onClick={handleBookNowClick(0)}>Book Now</Link></p>
                            </div>
                            <div className={styles.Item}>
                                <div className={styles.Img}>
                                    <img src={room2} alt="room1"></img>
                                    <div className={styles.PriceDiv}>
                                        <p>Deluxe</p>
                                    </div>
                                </div>
                                <h1>Deluxe Room</h1>
                                <div className={styles.info}>
                                    {/* <div>
                                        <img src={area} alt="area" />
                                        <span>80 sq ft</span>
                                    </div> */}
                                    <div>
                                        <img src={user} alt="area" />
                                        <span>2 Guests</span>
                                    </div>
                                    <div>
                                        <img src={bed} alt="area" />
                                        <span>2 Beds</span>
                                    </div>
                                </div>
                                <p>Experience the pinnacle of luxury in our Deluxe Rooms, where sophistication meets comfort amidst stunning mountain views. Indulge in deluxe amenities and spacious accommodations, guaranteeing an unforgettable stay for you and your guests.</p>
                                <p className={styles.explorepara}><Link to='/BookNow' onClick={handleBookNowClick(1)}>Book Now</Link></p>
                            </div>
                            {/* <div className={styles.Item}>
                                <div className={styles.Img}>
                                    <img src={room3} alt="room1"></img>
                                    <div className={styles.PriceDiv}>
                                        <p>Rs.3500</p>
                                    </div>
                                </div>
                                <h1>Family Room</h1>
                                <div className={styles.info}>
                                    <img src={area} alt="area" />
                                    <span>80 sq ft</span>
                                    <img src={user} alt="area" />
                                    <span>2 Guests</span>
                                    <img src={bed} alt="area" />
                                    <span>2 Beds</span>
                                </div>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eius voluptatibus tempora omnis blanditiis eaque vero consequuntur dignissimos ipsam provident.</p>
                                <p className={styles.explorepara}><a href='/BookNow'>Book Now</a></p>
                            </div> */}
                        </div>
                    </ScrollTopDiv>
                </div>
            </div >
        </div>
    )
}

export default About

import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './Restaurant.module.css'
import { motion } from "framer-motion"
import Component from '../../img/others/Component3.webp'
import restaurant1 from '../../img/others/Restaurant1.webp'
import { IoCall } from "react-icons/io5";
import ScrollAnimation from '../../components/Animation/ScrollAnimation'


const Restaurant = () => {
    return (
        <div>
            <TopBanner />
            <div className={styles.AboutSection}>
                <motion.div className={styles.RightSection}
                    initial={{ opacity: 0, x: -75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        bounce: 0.4,
                    }}>
                    <p className={styles.Subheading}>FINE DINING EXPERIENCES</p>
                    <h1>Indulge your senses and discover new flavors</h1>
                    <div className={styles.paragraph}>
                        <p>
                            We are committed to create wonderful memories for all guests with every stay. Carefully curated menus incorporate locally sourced ingredients into exquisite dishes that reflect the locals and the world around them.</p>
                        <p>Wherever you are in the world, we’ll take your taste buds on a journey they’ll never forget. Authentic flavors and culinary skills combine to create an unforgettable dining experience.</p>
                        <p> A reservation is recommended.</p>
                    </div>
                </motion.div>
                <motion.div className={styles.LeftSection}
                    initial={{ opacity: 0, x: 75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        staggerChildren: 0.5
                    }}>
                    <img src={Component} alt="Component" />
                </motion.div>
            </div>
            <div className={styles.RestaurantSection}>
                <motion.div className={styles.LeftSection}
                    initial={{ opacity: 0, x: -75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        bounce: 0.4,
                    }}>
                    <img src={restaurant1} alt="Component" />
                </motion.div>
                <motion.div className={styles.RightSection}
                    initial={{ opacity: 0, x: 75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        staggerChildren: 0.5
                    }}>
                    <h1>Opening Hours</h1>
                    <ul>
                        <li>Lunch: Daily 11:30 till 18:00</li>
                        <li>Dinner: Daily 18:00 till 22:00</li>
                        <li>Dress Code: Casual</li>
                    </ul>
                    <div className={styles.reservation}>
                        <IoCall />
                        <div>
                            <p>RESERVATION BY PHONE:</p>
                            <p>+41 22 345 67 88</p>
                        </div>
                    </div>



                </motion.div>

            </div>
            <div className={styles.Menu}>
                <div className={styles.Description}>
                    <ScrollAnimation>
                        <p className={styles.Subheading}>CHECK OUR TOP RATED DISHES</p>
                        <h1 className={styles.Headingh1}>Selected Menus</h1>
                    </ScrollAnimation>
                </div>
                <div className={styles.menuList}>
                    <div className={styles.LeftSection}>
                        <h1 className={styles.Heading2}>Starters</h1>
                        <div className={styles.item}>
                            <div className={styles.item1}>Milk Tea ------- $36</div>
                        </div>
                    </div>
                    <div className={styles.middleSection}>
                        <h1 className={styles.Heading2}>Starters</h1>
                    </div>
                    <div className={styles.RightSection}>
                        <h1 className={styles.Heading2}>Starters</h1>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Restaurant

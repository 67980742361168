import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './About.module.css'
import Icon1 from '../../img/Flaticon/farmhouse.webp'
import Component from '../../img/others/Component3.webp'
import Component2 from '../../img/others/Component4.webp'

// import ScrollTopDiv from '../../components/Animation/ScrollTopDiv'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import { motion } from "framer-motion"
// import ScrollTopDiv from '../../components/Animation/ScrollTopDiv'
import Counter from '../../components/CounterSection/CounterSection'


const About = () => {
    return (
        <div>
            <TopBanner />
            <div className={styles.Description}>
                <ScrollAnimation> <img src={Icon1} alt="house" />
                    <p className={styles.Subheading}>Welcome to Nagarkot Farm House</p>
                    <h1 className={styles.Headingh1}>In the Outskirt of the Kathmandu Valley,<br /> Outstanding Views</h1>
                </ScrollAnimation>
            </div>
            <div className={styles.AboutSection}>
                <motion.div className={styles.LeftSection}
                    initial={{ opacity: 0, x: -75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        bounce: 0.4,
                    }}>
                    <img src={Component} alt="Component" />
                </motion.div>
                <motion.div className={styles.RightSection}
                    initial={{ opacity: 0, x: 75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        staggerChildren: 0.5
                    }}>
                    <p className={styles.Subheading}>a short history of the farmhouse</p>
                    <h1>Her Enduring Journey</h1>
                    <p className={styles.paragraph}>
                        The Nagarkot Farm House Resort is one of the best hotel in Nepal since 28 years. It is wonderful place to stay with nice view.Uphill farmhouse with cute gardens and lovely coloured flowers. Simple rooms with fireplace inside. The food is simple and tasty. It’s best place to stay for writers, painters, poets, professionals. It will provide the solace and inspiration for beautiful creations.
                    </p>
                    <p className={styles.paragraph}>
                        Nagarot Farm House is conceived by Sambhu K. Lama and Sabine Lehman of <a href="https://hotelvajra.com/" rel="noreferrer" target="_blank">Hotel Vajra</a> , creating a serenity from where you can experience Nepal's natural Heritage.
                    </p>
                </motion.div>

            </div>
            <div className={styles.AboutSection}>
                <motion.div className={styles.RightSection}
                    initial={{ opacity: 0, x: -75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        bounce: 0.4,
                    }}>
                    <p className={styles.Subheading}>Endless Indoor and Outdoor Views</p>
                    <h1>Embark on Exploration: Every Path Unveils Spectacular Views to Enchant Your Senses.</h1>
                    <p className={styles.paragraph}>
                        The views are stunning, the staff is extremely friendly and the rooms are cozy with fireplace that keeps you warm in the winter. The balcony view of the Himalayas from room was spectacular.
                    </p>
                    <p className={styles.paragraph}>
                        In addition, the dining hall of the Farm House has an open fire place.You can sit, read, play chess, look a the mountains or chat with the manager.Nagarkot Farm house has a small stupa on  the Hilltop.
                    </p>

                    <p className={styles.paragraph}>
                        On clear mornings dawn unveils a vista of the eastern and central Himalayan ranges, from towering Manaslu to the distant pyramids of Everest.
                    </p>

                </motion.div>
                <motion.div className={styles.LeftSection}
                    initial={{ opacity: 0, x: 75 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{
                        ease: "linear",
                        type: "spring",
                        duration: 5,
                        staggerChildren: 0.5
                    }}>
                    <img src={Component2} alt="Component" />
                </motion.div>
            </div>
            <Counter />
            {/* 
            <div className={styles.Amendities}>

                <p className={styles.Subheading}><ScrollTopDiv>LUXURY, COMFORT & TAILOR-MADE SERVICE</ScrollTopDiv></p>
                <h1 className={styles.Headingh1}><ScrollTopDiv>Amenities & Services</ScrollTopDiv></h1>
                <p className={styles.paragraph}><ScrollTopDiv>All you need to know to ensure your trip is perfect. State-of-the-art amenities include a dramatic double-story loft lounge, spectacular rooftop lounge, Technogym fitness center, media corner, and a gracious 24/7 resident services team.</ScrollTopDiv></p>

                <div className={styles.Amenditiesimages}>
                    <motion.div className={styles.griditem}
                        initial={{ opacity: 0, x: -75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            bounce: 0.4,
                        }}>
                        <div className={styles.overlapLine}></div>
                        <img src={img1} alt="img1" />
                        <h1>Restaurants</h1>
                        <p>CULINARY EXPERIENCES</p>
                    </motion.div>
                    <motion.div className={styles.griditem}
                        initial={{ opacity: 0, x: -75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            bounce: 0.4,
                        }}>
                        <div className={styles.overlapLine}></div>
                        <img src={img2} alt="img2" />
                        <h1>Wellness Center</h1>
                        <p>SPA. SAUNA. MESSAGE</p>
                    </motion.div>
                    <motion.div className={styles.griditem}
                        initial={{ opacity: 0, x: 75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            staggerChildren: 0.5
                        }}>
                        <div className={styles.overlapLine}></div>
                        <img src={img3} alt="img3" />
                        <h1>Restaurants</h1>
                        <p>MEETINGS & EVENTS</p>
                    </motion.div>
                    <motion.div className={styles.griditem}
                        initial={{ opacity: 0, x: 75 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        viewport={{ once: true }}
                        transition={{
                            ease: "linear",
                            type: "spring",
                            duration: 5,
                            staggerChildren: 0.5
                        }}>
                        <div className={styles.overlapLine}></div>
                        <img src={img4} alt="img4" />
                        <h1>Restaurants</h1>
                        <p>TRANING SPACES</p>
                    </motion.div>

                </div>

            </div> */}
        </div>
    )
}

export default About

import React from 'react'
import styles from './Services.module.css'
import car from '../../img/Flaticon/carwash.webp'
import breakfast from '../../img/Flaticon/breakfast.webp'
import house from '../../img/Flaticon/house.webp'
import laundry from '../../img/Flaticon/laundryservice.webp'
import parking from '../../img/Flaticon/parking.webp'
import wifi from '../../img/Flaticon/wifi (1).png'
import { motion } from "framer-motion"

const Services = () => {
    return (
        <div className={styles.OuterSection}>
            <motion.div className={styles.griditem}
                initial={{ opacity: 0, x: -75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    type: "spring",
                    duration: 5,
                    bounce: 0.4,
                }}
            >
                <div className={styles.logo}>
                    <img src={car} alt="car" />
                </div>
                <div className={styles.text}>
                    <h6>Airport Pick-up Service</h6>
                    <p>
                        Enjoy our Airport Pick-up Service for an extra fee. Professional drivers ensure a seamless transition from the airport to your destination, allowing you to focus on your journey ahead.</p>
                </div>
            </motion.div>
            <motion.div className={styles.griditem}
                initial={{ opacity: 0, x: -75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    type: "spring",
                    duration: 5,
                    bounce: 0.4,
                }}
            >
                <div className={styles.logo}>
                    <img src={wifi} alt="car" />
                </div>
                <div className={styles.text}>
                    <h6>Wifi & Internet</h6>
                    <p>

                        Indulge in the luxury of uninterrupted high-speed Wi-Fi throughout your entire stay, providing you with seamless internet access for all your online needs and pleasures.</p>
                </div>
            </motion.div>

            <motion.div className={styles.griditem}
                initial={{ opacity: 0, x: 75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    type: "spring",
                    duration: 5,
                    staggerChildren: 0.5
                }}
            >
                {/* #D4B383 */}
                <div className={styles.logo}>
                    <img src={laundry} alt='laundry' />
                </div>
                <div className={styles.text}>
                    <h6>Laundry Services</h6>
                    <p>
                        Experience hassle-free laundry with our professional Laundry Services, ensuring your clothes and linens are fresh and crisp throughout your stay.</p>
                </div>
            </motion.div>
            <motion.div className={styles.griditem}
                initial={{ opacity: 0, x: -75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    type: "spring",
                    duration: 5,
                    bounce: 0.4,
                }}
            >
                <div className={styles.logo}>
                    <img src={parking} alt='laundry' />
                </div>
                <div className={styles.text}>
                    <h6>Private Parking Space</h6>
                    <p>
                        Enjoy convenient parking with our Private Parking Space, providing hassle-free accommodation for your vehicle throughout your stay.</p>
                </div>
            </motion.div>
            <motion.div className={styles.griditem}
                initial={{ opacity: 0, x: -75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    type: "spring",
                    duration: 5,
                    bounce: 0.4,
                }}
            >
                <div className={styles.logo}>
                    <img src={breakfast} alt='laundry' />

                </div>
                <div className={styles.text}>
                    <h6>Breakfast in Bed</h6>
                    <p>
                        Indulge in Breakfast in Bed, savoring a delightful morning meal delivered right to your room for a luxurious start to your day.</p>
                </div>
            </motion.div>
            <motion.div className={styles.griditem}
                initial={{ opacity: 0, x: 75 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{
                    ease: "linear",
                    type: "spring",
                    duration: 5,
                    staggerChildren: 0.5
                }}
            >
                <div className={styles.logo}>
                    <img src={house} alt='laundry' />

                </div>
                <div className={styles.text}>
                    <h6>Spa and Wellness</h6>
                    <p>
                        Rejuvenate with Spa and Wellness, treating yourself to a blissful escape that nourishes both body and soul during your stay.</p>
                </div>
            </motion.div>
        </div>
    )
}

export default Services

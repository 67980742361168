import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './LocalActivities.module.css'
import img from '../../img/others/img1.webp'
import mountain from '../../img/others/local3.webp'
import farmimg from '../../img/others/local1.webp'
import farmimg2 from '../../img/others/local2.webp'
import hike from '../../img/others/local4.webp'
import visit from '../../img/others/local5.webp'
import ScrollTopDiv from '../../components/Animation/ScrollTopDiv'
const LocalActivities = () => {
    return (
        <div>
            <TopBanner />
            <div className={styles.Container}>
                <div className={styles.gridItem}>
                    <ScrollTopDiv>

                        <div className={styles.imgDiv1}>
                            <img src={mountain} alt="imag" />
                            <div className={styles.text}>
                                <h2>Mountain Range Sightseeing</h2>
                                <p
                                >Our farmhouse offers stunning vistas of the Himalayan mountain ranges, stretching from the Lantang Range to Ganesh Himal. Enjoy unparalleled views and immerse yourself in the natural beauty of the region.</p>
                            </div>
                            <div className={styles.border}></div>
                        </div>
                    </ScrollTopDiv>

                </div>

                <div className={styles.gridItem}>
                    <ScrollTopDiv>
                        <div className={styles.imgDiv1}>
                            <img src={hike} alt="imag" />
                            <div className={styles.text}>
                                <h2>Forest Hike</h2>
                                <p
                                >Join our guided forest hikes to discover stunning landscapes and breathtaking views. Immerse yourself in the tranquility of the wilderness, where every step reveals hidden treasures.</p>
                            </div>
                            <div className={styles.border}></div>
                        </div>
                    </ScrollTopDiv>
                </div>


                <div className={styles.gridItem}>
                    <ScrollTopDiv>
                        <div className={styles.imgDiv1}>
                            <img src={visit} alt="imag" />
                            <div className={styles.text}>
                                <h2>Local Village Visit</h2>
                                <p
                                >Many day excursion can be made from the Farm house to Sakhu, Bajrayogini Temple, Changu Narayan Temple, or one can follow any of the small paths through typical Nepalese village.</p>
                            </div>
                            <div className={styles.border}></div>
                        </div>
                    </ScrollTopDiv>
                </div>

                <div className={styles.gridItem}>

                    <ScrollTopDiv>
                        <div className={styles.imgDiv1}>
                            <img src={farmimg2} alt="imag" />
                            <div className={styles.text}>
                                <h2>Organic Farm Visit</h2>
                                <p
                                >At The Farmhouse, our organic garden, managed by Sambhu K. Lama's team, boasts 10,000 trees, including 2,000 peach and apple trees. Pick fresh fruits straight from the orchard for a seasonal treat.</p>
                            </div>
                            <div className={styles.border}></div>
                        </div>
                    </ScrollTopDiv>
                </div>

                <div className={styles.gridItem}>
                    <ScrollTopDiv>
                        <div className={styles.imgDiv1}>
                            <img src={farmimg} alt="imag" />
                            <div className={styles.text}>
                                <h2>In-House Poultry Farm Visit</h2>
                                <p
                                >Our kitchen uses dairy from our poultry farm for organic, locally-sourced ingredients. Enjoy hands-on animal care and immerse yourself in farm life.</p>
                            </div>
                            <div className={styles.border}></div>
                        </div>
                    </ScrollTopDiv>

                </div>
                <div className={styles.gridItem}>
                    <ScrollTopDiv>

                        <div className={styles.imgDiv1}>
                            <img src={img} alt="imag" />
                            <div className={styles.text}>
                                <h2>Yoga and Meditation</h2>
                                <p
                                >Elevate your yoga and meditation experience in our expansive hall, purposefully crafted to foster serenity and accommodate groups of up to 25 individuals seeking inner peace and rejuvenation.</p>
                            </div>
                            <div className={styles.border}></div>
                        </div>
                    </ScrollTopDiv>

                </div>

            </div>
        </div>
    )
}

export default LocalActivities

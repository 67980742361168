import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner';
import styles from './Contact.module.css'
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { FaTripadvisor } from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";
import CounterSection from '../../components/CounterSection/CounterSection';
import { Link } from 'react-router-dom';
const Contact = () => {

    return (
        <div className={styles.ContactPage}>
            <TopBanner />
            <div className={styles.mapdiv}>
                <div className={styles.LeftSection}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4199.715816276!2d85.51897028910383!3d27.731918500427643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb0420c2511ea3%3A0x2babaa40d358969d!2sNagarkot%20Farmhouse!5e0!3m2!1sen!2snp!4v1706409982524!5m2!1sen!2snp"
                        title="maps"></iframe>
                </div>
                <div className={styles.RightSection}>
                    <div className={styles.details}>
                        <h5>RESERVATION</h5>
                        <h1>Direct Reservations</h1>
                        <p>Email: <a href="mailto:info@nagarkotfarmhouse.com">info@nagarkotfarmhouse.com</a></p>
                        <p>Reservations Call: <a href="tel:+9779749499983">+9779749499983</a></p>
                        <p>Call: <a href="tel:+9779802070675">+9779802070675</a></p>
                        <p className={styles.linklink}><Link to="/BookNow">Reserve Now</Link></p>

                        <h5>HOTEL ADDRESS</h5>
                        <h1>Nagarkot Farm House</h1>
                        <p>Madan Dev Pura, </p>
                        <p>Nagarkot,Kavre,</p>
                        <p>Nepal</p>
                        <div className={styles.socialLinks}>
                            <div className={styles.tooltip}>
                                <a href="https://www.facebook.com/nagarkotfarmhouseresort" rel="noreferrer" target="_blank" data-tip="Facebook">
                                    <FaFacebookF />
                                </a>
                                <span className={styles.tooltiptext}>Facebook</span>
                            </div>
                            <div className={styles.tooltip}>
                                <a href="https://www.instagram.com/nagarkot.farmhouse/" rel="noreferrer" target="_blank" data-tip="Instagram">
                                    <BsInstagram />
                                </a>
                                <span className={styles.tooltiptext}>Instagram</span>
                            </div>
                            <div className={styles.tooltip}>
                                <a href="https://www.tripadvisor.com" rel="noreferrer" target="_blank" data-tip="Tripadvisor">
                                    <FaTripadvisor />
                                </a>
                                <span className={styles.tooltiptext}>Tripadvisor </span>
                            </div>
                            <div className={styles.tooltip}>
                                <a href="https://www.booking.com/hotel/np/nagarkot-farmhouse-resort-pvt-ltd.en-gb.html?lang=en-gb&soz=1&lang_changed=1" rel="noreferrer" target="_blank" data-tip="Tripadvisor">
                                    <TbBrandBooking />
                                </a>
                                <span className={styles.tooltiptext}>Booking </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CounterSection />
        </div>
    )
}
export default Contact

import React, { useState, useEffect } from 'react'
import styles from './Home.module.css'
import YouTube from 'react-youtube'
import { createPortal } from 'react-dom'
import { useReservation } from "../../context/ReservationContext"; // Adjust the import path


import Modal from '../../components/Modal/Modal'
// import styles from './Home.module.css'
import HeroSection from '../../components/Home/HeroSection'
import About from '../../components/Home/About'
import Services from '../../components/Home/Services'
import NotJustSection from '../../components/Home/NotJustSection'
import Testimonial from '../../components/Home/Testimonial'

const Home = () => {
    // const [modalOpen, setModalOpen] = useState(true);
    // eslint-disable-next-line
    const [src, setSrc] = useState('//cdn-cf-east.streamable.com/video/mp4/oea9wv.mp4?Expires=1697165580&Signature=Y-rQB1ajgQYkauId0kN88dxRWOS2JPx0cyZkI4~o2w6PYBSe8Xyd9OOZWphjyOWx0y188PcCfgMHDXWxaVGIUIt9xiKYmaw~2QhdnI0JUvZNgEuVdmP40XAmm6zpefjAtMiyQYUG0Dr-QLC8Min~Xt3JiZrl3sbeJ2QQJXikOjiCSeYmvGI6eqoheumDMM~aMpVL0xnAQt~7hNYHQ1OIglSoFYM9W7TKsmlsSiE5hmv5sRGRVywqf9ZfsFowBpgwxa1nMGGpuZ10~Smq~EP9WtTAL91OByPn9DXJQL-mGSWGTiPVa2BBLhI1ZEUmL5Pr4PRDMBatsrQX9U6TA2OqdQ__&Key-Pair-Id=APKAIEYUVEN4EVB2OKEQ');
    const {
        homeModalOpen,
        sethomeModalOpen,
    } = useReservation();
    const handleButtonClick = (value) => {
        sethomeModalOpen(false)
    }
    const handleVideoEnd = () => {
        sethomeModalOpen(false)
    }
    // eslint-disable-next-line
    const handleVideoClick = (value) => {
        sethomeModalOpen(true)
    }
    // const videoUrl = 'https://youtu.be/uWNArlmpl6E';
    const videoUrl = 'https://youtu.be/TPWxlQJpvpQ'
    // const videoUrl = process.env.PUBLIC_URL + '/public/Video1.mp4'
    const videoId = extractVideoId(videoUrl);

    function extractVideoId(url) {
        // Extract the video ID from the YouTube URL
        // eslint-disable-next-line
        const match = url.match(/(?:youtu\.be\/|youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/);
        return match && match[1];
    }
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);


    useEffect(() => {
        const handleResize = () => {
            const newWindowWidth = window.innerWidth;
            setWindowWidth(newWindowWidth);
            setWindowHeight(window.innerHeight);
            if (newWindowWidth < 1080) {
                sethomeModalOpen(false);
            }

        };
        if (window.innerWidth < 1080) {
            sethomeModalOpen(false);
        }
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [sethomeModalOpen]);

    return (
        <>
            {homeModalOpen &&
                createPortal(
                    <Modal
                        onClose={handleButtonClick}
                        onSubmit={handleButtonClick}
                        onCancel={handleButtonClick}
                    >
                        <div className={styles.modalDiv} >
                            <>
                                {/* {videoId && (
                                    <iframe
                                        width={windowWidth}
                                        height={windowHeight}
                                        src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                                        frameBorder="0"
                                        allowFullScreen
                                        title="YouTube Video Player"
                                    ></iframe>
                                )} */}
                                <div className={styles.CloseButton} onClick={handleButtonClick}>Go To Site</div>
                                {videoId && (
                                    <YouTube
                                        videoId={videoId}
                                        opts={{
                                            width: windowWidth,
                                            height: windowHeight,
                                            playerVars: {
                                                autoplay: 1,
                                                controls: 1,
                                                mute: 1,
                                            },
                                        }}
                                        // onReady={handleVideoReady}
                                        onEnd={handleVideoEnd}
                                    />
                                )}

                            </>

                        </div>
                    </Modal>,
                    document.body
                )}
            {/* <Gallery /> */}
            <HeroSection />
            <div className={styles.VideoSection}>
                <NotJustSection />
            </div>
            <About />
            <Services />

            <Testimonial />


        </>
    )
}

export default Home

import React from 'react'
import TopBanner from '../../components/TopBanner/TopBanner'
import styles from './Rooms.module.css'
// import room1 from '../../img/others/room1.webp'
import room1 from '../../img/others/deluxe1.webp'
import room2 from '../../img/others/deluxe2.webp'

// import room2 from '../../img/others/room2.webp'
// import area from '../../img/Flaticon/wide.webp'
import user from '../../img/Flaticon/user.webp'
import bed from '../../img/Flaticon/bed.webp'
import { useReservation } from "../../context/ReservationContext"; // Adjust the import path
import { Link } from 'react-router-dom'
import ScrollAnimation from '../../components/Animation/ScrollAnimation'
import ScrollTopDiv from '../../components/Animation/ScrollTopDiv'
const Rooms = () => {
    const {
        setRoomType
    } = useReservation();
    const handleBookNowClick = (flag) => {
        if (flag === 0) {
            setRoomType('Standard Room');
        }
        else {
            setRoomType('Deluxe Room');
        }
    };
    return (
        <div>
            <TopBanner />
            <div className={styles.Description}>
                <ScrollAnimation>
                    <p className={styles.Subheading}>Welcome to Nagarkot Farm House</p>
                    <h1 className={styles.Headingh1}>Enjoy Mountain Views from Our <br /> Lovely Private Rooms</h1>
                    {/* <div className={styles.MoreDescription}><p>At Nagarkot Farm House, we provide a variety of lodging options to accommodate groups of all sizes. Whether you're planning a corporate retreat, a family vacation, a romantic getaway, or a cozy cabin stay, we have the perfect accommodation for you. Our dedicated team ensures that your experience matches the spectacular views of our serene surroundings.</p></div> */}
                    <div className={styles.MoreDescription}>
                        <p>The Farmhouse has four rooms with common bath, seven deluxe rooms and four rooms with attached bath.
                            Cosy interiors are designed to blend with its unique cultural and natural
                            surroundings. Each room has a view of the Himalayas</p>
                    </div>

                </ScrollAnimation>
                <div className={styles.selectRooms}>
                    <ScrollTopDiv>
                        <div className={styles.RoomOptions}>
                            <div className={styles.Item}>
                                <div className={styles.Img}>
                                    <img src={room1} alt="room1"></img>
                                    <div className={styles.PriceDiv}>
                                        <p>Standard</p>
                                    </div>
                                </div>
                                <h1>Standard Room</h1>
                                <div className={styles.info}>
                                    {/* <div>
                                        <img src={area} alt="area" />
                                        <span>80 sq ft</span>
                                    </div> */}
                                    <div>
                                        <img src={user} alt="area" />
                                        <span>2 Guests</span>
                                    </div>
                                    <div>
                                        <img src={bed} alt="area" />
                                        <span>2 Beds</span>
                                    </div>
                                </div>
                                <p>Our remarkable standard rooms offer all the amenities needed for a comfortable and cozy stay, accompanied by breathtaking views of the surrounding mountain ranges. Special requests for accommodating more than two people can be accommodated.</p>
                                <p className={styles.explorepara}><Link to='/BookNow' onClick={handleBookNowClick(0)}>Book Now</Link></p>
                            </div>
                            <div className={styles.Item}>
                                <div className={styles.Img}>
                                    <img src={room2} alt="room1"></img>
                                    <div className={styles.PriceDiv}>
                                        <p>Deluxe</p>
                                    </div>
                                </div>
                                <h1>Deluxe Room</h1>
                                <div className={styles.info}>
                                    {/* <div>
                                        <img src={area} alt="area" />
                                        <span>80 sq ft</span>
                                    </div> */}
                                    <div>
                                        <img src={user} alt="area" />
                                        <span>2 Guests</span>
                                    </div>
                                    <div>
                                        <img src={bed} alt="area" />
                                        <span>2 Beds</span>
                                    </div>
                                </div>
                                <p>Experience the pinnacle of luxury in our Deluxe Rooms, where sophistication meets comfort amidst stunning mountain views. Indulge in deluxe amenities and spacious accommodations, guaranteeing an unforgettable stay for you and your guests.</p>
                                <p className={styles.explorepara}><Link to='/BookNow' onClick={handleBookNowClick(1)}>Book Now</Link></p>
                            </div>
                            {/* <div className={styles.Item}>
                                <div className={styles.Img}>
                                    <img src={room3} alt="room1"></img>
                                    <div className={styles.PriceDiv}>
                                        <p>Rs.3500</p>
                                    </div>
                                </div>
                                <h1>Family Room</h1>
                                <div className={styles.info}>
                                    <img src={area} alt="area" />
                                    <span>80 sq ft</span>
                                    <img src={user} alt="area" />
                                    <span>2 Guests</span>
                                    <img src={bed} alt="area" />
                                    <span>2 Beds</span>
                                </div>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam eius voluptatibus tempora omnis blanditiis eaque vero consequuntur dignissimos ipsam provident.</p>
                                <p className={styles.explorepara}><a href='/BookNow'>Book Now</a></p>
                            </div> */}
                        </div>
                    </ScrollTopDiv>
                </div>
            </div>
        </div>

    )
}

export default Rooms

import React, { useEffect, useRef, useState } from 'react'
import styles from './CounterSection.module.css'
import { useInView } from 'framer-motion'
import ScrollAnimation from '../Animation/ScrollAnimation';

const CounterAnimation = ({ children, end }) => {
    const ref = useRef();
    const isInView = useInView(ref, { triggerOnce: true });

    const [count, setCount] = useState(1);

    useEffect(() => {
        let intervalId;
        if (isInView && count < end) {
            intervalId = setInterval(() => {
                setCount(prevCount => prevCount + 1);
            }, 100); // Adjust the interval duration as needed
        }

        return () => clearInterval(intervalId);
    }, [isInView, count, end]);

    return (
        <div ref={ref}>
            {Math.min(count, end)}
        </div>
    );
};
const CounterSection = () => {
    return (
        <div className={styles.CounterSection}>
            <p className={styles.Subheading}> <ScrollAnimation>ENJOY YOUR STAY AT THE HOTEL</ScrollAnimation></p>
            <h1><ScrollAnimation>Spend your comfortable holiday in the top<br /> of the beautiful Nagarkot HillSide </ScrollAnimation></h1>
            <div className={styles.counter}>
                <div className={styles.item}>
                    <h1><CounterAnimation end={4} /></h1>
                    <p>Standard Rooms</p>
                </div>
                <div className={styles.item}>
                    <h1><CounterAnimation end={7} /></h1>
                    <p>Deluxe Rooms</p>
                </div>

            </div>
        </div>
    )
}

export default CounterSection
